// TODO: figure out if we need these styles
.hub-icon-sm .c-icon-button {
  font-size: 10px;
}

.dashboard-tile-card {
  .mat-ripple {
    overflow: visible;
  }
}
